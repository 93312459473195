<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="public-areas public-area-user-top margin-bottom-10">
        <div class="weui-cells public-area-user-main margin-top-0">
          <div class="weui-cell">
            <div class="weui-cell__hd">
              <img
                class="avatar"
                id="avatar-preview"
                :src="avatarUrl"
              />
            </div>
            <div class="weui-cell__bd">
              <div class="weui-flex">
                <div class="weui-flex__item text-center">
                  <div>
                    <span href="/m/coin" class="text-danger"
                      >{{totalScore}}<i class="icon icon-public-coins icon-7"></i
                    ></span>
                  </div>
                  <div class="remark">累计收益</div>
                </div>
                <div class="weui-flex__item text-center">
                  <div>
                    <span href="/m/coin" class="text-danger"
                      >{{availableScore + frozenScore}}<i class="icon icon-public-coins icon-7"></i
                    ></span>
                  </div>
                  <div class="remark">账户余额</div>
                </div>
                <div class="weui-flex__item text-center">
                  <div>
                    <span href="/m/coin/freeze" class="text-green"
                      >{{availableScore}}<i class="icon icon-public-coins text-green"></i
                    ></span>
                  </div>
                  <div class="remark">
                    可提现
                    <a
                      href="javascript:;"
                      @click="showTip"
                      class="show-freeze-remark"
                      ><i class="icon icon-public-help"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="public-areas public-area-user-list margin-bottom-10">
        <div class="weui-cells margin-top-0">
          <a
            class="weui-cell weui-cell_access"
            v-if="inviterNickName"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-peoples icon-7"></i>邀请人： {{inviterNickName}}</p>
            </div>
            <!-- <div class="weui-cell__ft"></div> -->
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'surveyHistory' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-survey icon-7"></i>我的作答</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'coin' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-coins icon-7"></i>余额明细</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'withdraw' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-tixian icon-5"></i>提现</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'prizeHistory' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-bill icon-3"></i>提现记录</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'rule' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-detail icon-4"></i>问卷规则</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
        </div>
      </div>
      <div class="public-areas public-area-user-list margin-bottom-10">
        <div class="weui-cells margin-top-0">
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'account' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-man-set icon-1"></i>账号设置</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a class="weui-cell weui-cell_access" @click="$router.push({ name: 'invitationInfo' })">
            <div class="weui-cell__bd">
              <!-- <p><i class="icon icon-public-peoples icon-6"></i>推广码</p> -->
              <p><i class="icon icon-public-peoples icon-6"></i>我的朋友</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'inviteRule' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-invite1 icon-1"></i>邀请规则</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'attribute' })"
          >
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-record icon-3"></i>个人属性</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a class="weui-cell weui-cell_access" @click="showQrcodeDialog">
          <!-- <a class="weui-cell weui-cell_access" href="https://qm.qq.com/cgi-bin/qm/qr?k=YAKxTOhNZdvNPQZRnUH_9NFZnffXj7vQ&jump_from=webapi&authKey=4OKBjQK15HBpeGY3Ek/q7nFGJw8PtEWiy63nwCn3JCUiogBt1oxq+HqrfFwhljAt"> -->
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-feedback icon-2"></i>在线反馈</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a class="weui-cell weui-cell_access" @click="goNeedPicList">
            <div class="weui-cell__bd">
              <p><i class="icon icon-public-img icon-1"></i>上传答题凭证</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          <a class="weui-cell weui-cell_access" @click="goPromotion">
            <div class="weui-cell__bd">
              <!-- <p><i class="icon icon-public-peoples icon-6"></i>推广码</p> -->
              <p><i class="icon icon-public-vcode icon-6"></i>推广码</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
          
        </div>
      </div>
      <div class="public-areas public-area-user-list margin-bottom-10">
        <div class="weui-cells margin-top-0">
          <a class="weui-cell" @click="$router.push({ name: 'login' })">
            <div class="weui-cell__bd">
              <p class="public-btn-logout">退出登录</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>
<script>
import { getUserInfo } from '../api/index'
// import { getParams } from '../utils/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "users",
  components: {
    tabbar
  },
  data () {
    return {
      nickName: '', // 昵称
      avatarUrl: './assets/img/face.png', //	头像url
      totalScore:	0, // 累计积分
      availableScore:	0, // 可用积分
      frozenScore: 0, // 冻结积分
      promotionCode: '', // 个人推广码
      inviterNickName: '', // 邀请人
      isWX: false
    }
  },
  created () {
    const ua = navigator.userAgent.toLowerCase()
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
      this.isWX = true
    }
  },
  mounted () {
  },
  methods: {
    // 查询用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.nickName = result.nickName
          this.totalScore = result.totalScore
          this.availableScore = result.availableScore
          this.frozenScore = result.frozenScore
          this.promotionCode = result.promotionCode
          this.inviterNickName = result.inviterNickName
          if (result.avatarUrl) {
            this.avatarUrl = result.avatarUrl
          }
          this.storageSet('SURVEY_mobile', result.mobile)
          this.storageSet('SURVEY_name', result.name)
          this.storageSet('SURVEY_nickName', result.nickName)
          this.storageSet('SURVEY_email', result.email)
          this.storageSet('SURVEY_alipayAcc', result.alipayAcc)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    storageSet(key, val) {
      if (!key || !val) return false
      localStorage.setItem(key, val)
    },
    showTip() {
      let content = "";
      content += '<div class="text-left" >';
      content +=
        '<p class="margin-bottom-10">因部分用户答题过程中乱答乱写，严重影响数据质量。为了保证作答有效，部分问卷作答成功获得奖励后，将暂时冻结部分成功奖励等待质量审核，等待审核期间这部分金额不可提现。具体规则如下：</p>';
      content +=
        '<p class="margin-bottom-10">1、如果审核不合格，将从账户余额中扣除本次作答的全额奖励</p>';
      content +=
        '<p class="margin-bottom-10">2、如果审核通过，冻结的金币将立即取消冻结、恢复为可提现状态</p><br>';
      content +=
        '<p class="margin-bottom-10 text-red">温馨提示：为了大家的利益不受损失，请大家认真作答、切勿乱答乱写哦~</p>';

      content += "</div>";
      this.$dialog.show({
        title: "为何有部分余额暂时不能提现",
        content: content,
        showCancel: false,
        okText: "确定",
      });
    },
    showQrcodeDialog() {
      if (this.isWX) {
        // let content = '<img style="width:60%;" src="./assets/img/qq_qun_qr.jpg"><div class="padding-15">长按识别二维码，加入QQ群聊</div>';
        let content = '<div>加QQ群：835535251</div>';
        this.$dialog.show({
          title: "每日调查任务",
          content: content,
          showCancel: false,
          okText: "确定",
        });
      } else {
        const url = 'https://qm.qq.com/cgi-bin/qm/qr?k=YAKxTOhNZdvNPQZRnUH_9NFZnffXj7vQ&jump_from=webapi&authKey=4OKBjQK15HBpeGY3Ek/q7nFGJw8PtEWiy63nwCn3JCUiogBt1oxq+HqrfFwhljAt'
        location.href = url
      }
    },
    // 跳转到待上传凭证列表
    goNeedPicList () {
      this.$router.push({name: 'needPicList'})
    },
    // 跳转到推广码页面
    goPromotion () {
      const promotionCode = this.promotionCode
      this.$router.push({name: 'promotion', query: {promotionCode}})
    },
    doWxAuthCb (flag) {
      console.log('cb', flag)
      this.doGetUserInfo()
    }
  },
};
</script>
<style lang="css" scoped>
.public-container{
  margin: unset;
}
</style>
